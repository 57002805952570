<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div>
        <h2>{{ $t('channels') }}</h2>
      </div>
      <v-card tile flat>
        <add-channel @save="onChannelSave" />
      </v-card>
    </div>
    <ChannelList />
  </div>
</template>

<script>
import ChannelList from '@/messaging/components/channel/list.vue'
import AddChannel from '@/messaging/components/channel/add-channel.vue'

export default {
  components: {
    AddChannel,
    ChannelList
  },
  data() {
    return {
      filterTerm: '',
      showFilters: false,
      advancedFilters: [],
      includeArchived: false,
      hasFilters: false,
      filterCount: 0
    }
  },
  methods: {
    onChannelSave(channel) {
      console.log('channel saved', channel)
    }
  }
}
</script>
