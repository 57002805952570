<template>
  <v-card flat height="90vh" width-full>
    <v-row>
      <v-col cols="4" md="2" class="flex-grow-0 flex-shrink-0 border-right-1">
        <v-list dense nav>
          <v-list-item-group
            v-model="selectedChannelIndex"
            mandatory
            color="primary"
          >
            <v-list-item
              v-for="channel in channels"
              :key="channel.id"
              @click="selectedChannel = channel"
            >
              <v-list-item-content>
                <v-list-item-title v-text="channel.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        cols="8"
        md="10"
        style="min-width: 400px;width=100%; max-height: 80vh;min-height:350px;"
        class="flex-grow-1 flex-shrink-0"
      >
        <div
          v-if="selectedChannel"
          class="d-flex flex-column mb-6"
          style="height:90vh"
        >
          <!-- title -->
          <v-card flat dense class="sticky">
            <v-card-title>
              <h4>{{ selectedChannel.name }} Messages</h4>
            </v-card-title>
          </v-card>

          <!-- message list -->
          <div style="flex: 2; overflow: scroll;">
            <v-card
              flat
              width="100%"
              class="overflow-y-auto frame"
              v-if="selectedChannel"
              style="position:relative;top:0;overflow: scroll;"
            >
              <v-card-text>
                <h4></h4>
                <message-card
                  v-for="message in messages"
                  :key="message.id"
                  :message="message"
                />
              </v-card-text>
              <span ref="messagebottom"></span>
            </v-card>
          </div>

          <!-- message form-->
          <v-card flat width="100%">
            <v-card-text>
              <v-form>
                <v-textarea
                  v-model="message"
                  label="Message"
                  rows="3"
                  filled
                  auto-grow
                ></v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" large @click="sendMessage">
                {{ $t('send') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import profileMixin from '@/components/mixins/profile'
import MessageCard from '../message/card.vue'

export default {
  mixins: [profileMixin],
  components: {
    MessageCard
  },
  data() {
    return {
      selectedChannel: null,
      selectedChannelIndex: null,
      message: ''
    }
  },
  computed: {
    ...mapGetters({
      channels: 'messaging/channels'
    }),

    messages() {
      return this.selectedChannel
        ? [...this.selectedChannel.messages].sort(
          (a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime)
        )
        : []
    }
  },
  watch: {
    selectedChannelIndex(val) {
      this.selectedChannel = this.channels[val]
    },
    channels() {
      if (this.channels.length > 0 && this.selectedChannel === null) {
        this.selectedChannel = this.channels[0]
      }
    },
    tenantId(val) {
      this.$store.dispatch('messaging/getChannels', { tenantId: val })

      this.$messageHub.connection.on('MessageCreated', (channelId, message) => {
        console.log('received message', channelId, message)

        const channel = this.channels.find(c => c.id === channelId)
        if (channel) {
          channel.messages.push(message)
        }
      })
    }
  },
  mounted() {
    if (this.tenantId) {
      this.$store.dispatch('messaging/getChannels', { tenantId: this.tenantId })
    }
  },
  methods: {
    sendMessage() {
      const payload = {
        tenantId: this.tenantId,
        channelId: this.selectedChannel.id,
        message: { content: this.message }
      }

      this.$store.dispatch('messaging/sendMessage', payload)
      this.message = ''
      this.$vuetify.goTo(this.$refs.messagebottom)
    }
  }
}
</script>

<style>
.frame {
  overflow-y: auto;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
